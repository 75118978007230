import React, { useEffect, useState, useRef } from "react";
import offerbackgraundbanner from "../images/GiveawayBanner.png";
import Countdown from "react-countdown";
import PlanCard from "../components/PlanCard";
import { useDispatch, useSelector } from "react-redux";
import { Getgiveaways } from "../ReduxToolkit/Slices/Deals/Deals.action";
import { LazyLoadImage } from "react-lazy-load-image-component";
import { BaseUrl } from "../ReduxToolkit/Apis";
import "react-lazy-load-image-component/src/effects/blur.css";
import toast from "react-hot-toast";
import LoginModal from "../components/modal/LoginModal";
import SignupModal from "../components/modal/SignupModal";
import ForgotPassword from "../components/modal/ForgotPassword";
import {
  GetsubscriptionPlans,
  VerifiySubscription,
} from "../ReduxToolkit/Slices/Subscription/Subscription.action";
import { GetUserProfileDetails } from "../ReduxToolkit/Slices/Auth/Auth.action";
import { useLocation, useNavigate } from "react-router-dom";
import CongratsModal from "../components/modal/CongratsModal";
import SubscriptionPlanCard from "../components/SubscriptionPlanCard";

const Giveaways = () => {
  const dispatch = useDispatch();
  let navigate = useNavigate();
  let logindata = JSON.parse(localStorage.getItem("SaveOn_Web_Cread"));
  // -------- Is Check Login -------------
  const [isLoginOpen, setIsLoginOpen] = useState(false);
  const [isSignupOpen, setIsSignupOpen] = useState(false);
  const [isForgotPasswordOpen, setIsForgotPasswordOpen] = useState(false);
  const openSignupModal = () => setIsSignupOpen(true);
  const closeSignupModal = () => setIsSignupOpen(false);

  const openLoginModal = () => setIsLoginOpen(true);
  const closeLoginModal = () => setIsLoginOpen(false);

  const openForgotPassModal = () => setIsForgotPasswordOpen(true);
  const closeForgotPassModal = () => setIsForgotPasswordOpen(false);

  const switchToForgotPass = () => {
    closeLoginModal();
    openForgotPassModal();
  };
  const switchToLogin = () => {
    closeSignupModal();
    closeForgotPassModal();
    openLoginModal();
  };

  const switchToSignup = () => {
    closeLoginModal();
    openSignupModal();
  };
  useEffect(() => {
    dispatch(Getgiveaways());
    dispatch(GetsubscriptionPlans());
    dispatch(GetUserProfileDetails(logindata?.id));
  }, [dispatch, navigate]);
  const Deals = useSelector((store) => store?.Deals);
  let Subscription = useSelector((store) => store?.Subscription);
  let AuthCheck = useSelector((store) => store?.Auth);
  console.log(AuthCheck);

  const [showCongratsModal, setShowCongratsModal] = useState(false);
  const location = useLocation();
  let [verifiyplandata, setverifiyplandata] = useState(null);
  let [Verifiysubloader, setVerifiysubloader] = useState(false);
  useEffect(() => {
    const callAPI = async () => {
      try {
        const urlParams = new URLSearchParams(location.search);
        const token = urlParams.get("token");
        const subscription_id = urlParams.get("subscription_id");
        const type = urlParams.get("type");
        if (token) {
          setVerifiysubloader(true);
          let SubscriptionData = new FormData();
          SubscriptionData?.append("id", subscription_id);
          SubscriptionData?.append("type", type);
          await dispatch(VerifiySubscription(SubscriptionData))
            .unwrap()
            .then((result) => {
              if (result?.r?.status === "ACTIVE") {
                setVerifiysubloader(false);
                setShowCongratsModal(true);
                setverifiyplandata(result?.r);
                urlParams.delete("token");
                urlParams.delete("type");
                urlParams.delete("subscription_id");
                urlParams.delete("ba_token");
                urlParams.delete("PayerID");
                navigate({ search: urlParams.toString() }, { replace: true });
                dispatch(Getgiveaways());
                dispatch(GetsubscriptionPlans());
                dispatch(GetUserProfileDetails(logindata?.id));
              } else {
                setVerifiysubloader(false);
                setShowCongratsModal(true);
                navigate({ search: urlParams.toString() }, { replace: true });
                dispatch(Getgiveaways());
                dispatch(GetsubscriptionPlans());
                dispatch(GetUserProfileDetails(logindata?.id));
              }
            });
        }
      } catch (error) {
        console.error("Error calling API:", error);
      }
    };
    callAPI();
  }, [location.search, dispatch, navigate]);

  const subscriptionSectionRef = useRef(null);
  const CheckIsLogin = () => {
    const isLoggedIn = localStorage.getItem("SaveOn_Web_Cread");
    if (isLoggedIn) {
      if (
        AuthCheck?.UserDetailsByid?.is_subscribe !== 4 &&
        AuthCheck?.UserDetailsByid?.is_subscribe !== 5
      ) {
        toast(" To enter in Giveaway, please subscribe ! ", {
          icon: "❌",
          style: {
            borderRadius: "10px",
            background: "#333",
            color: "#fff",
          },
        });
        subscriptionSectionRef.current.scrollIntoView({
          behavior: "smooth",
          block: "start",
        });
      }
    } else {
      // toast("Please Login  !", {

      //   style: {
      //     borderRadius: "10px",
      //     background: "#333",
      //     color: "#fff",
      //   },
      // });
      openLoginModal();
    }
  };

  let redirectToResult = (data) => {
    window.open(data?.url, "_blank");
  };

  return (
    <div className="bg-[#FAFAFA]">
      {Verifiysubloader && (
        <div className="fixed z-50 top-0 left-0 w-full h-full flex items-center justify-center  bg-black bg-opacity-50">
          <span className="loader"></span>
        </div>
      )}
      <div className="w-full relative m-auto ">
        <LazyLoadImage
          effect="blur"
          className="object-cover w-full h-[40vh] md:h-[60vh]"
          src={offerbackgraundbanner}
          alt="giveaway"
          height={"100%"}
          width={"100%"}
        />
        <div className="absolute top-0 m-auto w-full h-full flex">
          <div className="text-zinc-50 w-full md:1/2 flex flex-col justify-center align-middle text-center m-auto ">
            <h2 className="font-extrabold text-cxl md:text-c_xl">Giveaways</h2>
            <p className="text-csm md:text-c_sm w-[95%] md:w-[50%] lg:w-[30%] text-center m-auto">
              It is a long established fact that a reader will be distracted by
              the readable content of a.
            </p>
          </div>
        </div>
      </div>
      <div className="w-[90%] max-w-[1500px] mx-auto">
        {Deals?.giveaways?.map((data, index) => {
          const currentDate = Date.now();
          const startDate = new Date(data?.start_date).getTime();
          const endDate = new Date(data?.end_date).getTime();
          const oneWeekBeforeStartDate = startDate - 7 * 24 * 60 * 60 * 1000; // 1 week before start date
          const isSubscribed = AuthCheck?.UserDetailsByid?.is_subscribe === 1;
          const isMoreThanAWeekAway = currentDate < oneWeekBeforeStartDate;
          const isWithinOneWeek =
            currentDate >= oneWeekBeforeStartDate && currentDate < startDate;
          const isLive = currentDate >= startDate && currentDate < endDate;
          const isEnded = currentDate >= endDate;

          return (
            <div
              key={index}
              className="flex flex-col md:flex-row items-center gap-4 md:gap-12 py-8 w-full border-transparent border-2 hover:bg-light-blue hover:border-primary-blue hover:border-2 p-2 rounded-lg transition-all duration-300"
            >
              <div className="sm:w-[350px] md:w-[400px] lg:w-[450px] xl:w-[500px] p-4 border-primary-blue rounded-lg">
                <LazyLoadImage
                  effect="blur"
                  src={data?.image !== 0 ? `${BaseUrl}${data?.image}` : ""}
                  alt="giveaway"
                  height={"100%"}
                  width={"100%"}
                />
              </div>
              <div className="flex flex-col justify-start items-start gap-4 w-[50%]">
                <h3
                  className="text-clg md:text-c_lg xl:text-cxl text-dark-text"
                  style={{
                    display: "-webkit-box",
                    WebkitBoxOrient: "vertical",
                    WebkitLineClamp: 1,
                    overflow: "hidden",
                    textOverflow: "ellipsis",
                    whiteSpace: "normal",
                    transition: "color 0.3s ease-in-out",
                  }}
                >
                  {data?.title}
                </h3>
                <p
                  style={{
                    display: "-webkit-box",
                    WebkitBoxOrient: "vertical",
                    WebkitLineClamp: 2,
                    overflow: "hidden",
                    textOverflow: "ellipsis",
                    whiteSpace: "normal",
                    transition: "color 0.3s ease-in-out",
                  }}
                  className="text-desc-text sm:w-[300px] text-sm break-words"
                >
                  {data?.subtitle}
                </p>
                {/* Conditional Rendering */}
                {isMoreThanAWeekAway && (
                  <button
                    disabled
                    className="bg-dark-blue py-3 px-4 rounded-lg w-fit text-white transition-all duration-300 ease-in-out hover:bg-primary-blue"
                  >
                    Coming Soon ⏳
                  </button>
                )}
                {isWithinOneWeek && (
                  <>
                    <Countdown
                      date={startDate}
                      renderer={({ days, hours, minutes, seconds }) => (
                        <div className="flex justify-center gap-2">
                          <div className="border-primary-blue text-sm md:text-base  flex flex-col items-center rounded-lg w-[60px] sm:w-[70px] border p-2 text-[rgba(143,12,63,1)]">
                            <span className="text-primary-blue sm:tex-xl lg:text-2xl font-semibold">
                              {days}
                            </span>
                            Days
                          </div>
                          <span className="text-[rgba(143,12,63,1)] sm:tex-xl lg:text-2xl font-semibold mt-4">
                            :
                          </span>
                          <div className="border-primary-blue text-sm md:text-base   flex flex-col items-center rounded-lg w-[60px] sm:w-[70px] border p-2 text-[rgba(143,12,63,1)]">
                            <span className="text-primary-blue sm:tex-xl lg:text-2xl font-semibold">
                              {hours}
                            </span>{" "}
                            Hours
                          </div>
                          <span className="text-[rgba(143,12,63,1)] sm:tex-xl lg:text-2xl font-semibold mt-4">
                            :
                          </span>
                          <div className="border-primary-blue text-sm md:text-base   flex flex-col items-center rounded-lg w-[60px] sm:w-[70px] border p-2 text-[rgba(143,12,63,1)]">
                            <span className="text-primary-blue sm:tex-xl lg:text-2xl font-semibold">
                              {minutes}
                            </span>{" "}
                            Minutes
                          </div>
                          <span className="text-[rgba(143,12,63,1)] sm:tex-xl lg:text-2xl font-semibold mt-4">
                            :
                          </span>
                          <div className="border-primary-blue text-sm md:text-base  flex flex-col items-center rounded-lg w-[60px] sm:w-[70px] border p-2 text-[rgba(143,12,63,1)]">
                            <span className="text-primary-blue sm:tex-xl lg:text-2xl font-semibold">
                              {seconds}
                            </span>{" "}
                            Seconds
                          </div>
                        </div>
                      )}
                    />
                    <h1 className="text-primary-blue font-semibold">
                      Giveaway Starts In 🕑
                    </h1>
                  </>
                )}

                {isLive && (
                  <>
                    <Countdown
                      date={endDate}
                      renderer={({ days, hours, minutes, seconds }) => (
                        <div className="flex justify-center gap-2">
                          <div className="border-primary-blue text-sm md:text-base bg-light-blue flex flex-col items-center rounded-lg w-[60px] sm:w-[70px] border p-2 text-[rgba(143,12,63,1)]">
                            <span className="text-primary-blue sm:tex-xl lg:text-2xl font-semibold">
                              {days}
                            </span>
                            Days
                          </div>
                          <span className="text-[rgba(143,12,63,1)] sm:tex-xl lg:text-2xl font-semibold mt-4">
                            :
                          </span>
                          <div className="border-primary-blue text-sm md:text-base bg-light-blue flex flex-col items-center rounded-lg w-[60px] sm:w-[70px] border p-2 text-[rgba(143,12,63,1)]">
                            <span className="text-primary-blue sm:tex-xl lg:text-2xl font-semibold">
                              {hours}
                            </span>{" "}
                            Hours
                          </div>
                          <span className="text-[rgba(143,12,63,1)] sm:tex-xl lg:text-2xl font-semibold mt-4">
                            :
                          </span>
                          <div className="border-primary-blue text-sm md:text-base bg-light-blue flex flex-col items-center rounded-lg w-[60px] sm:w-[70px] border p-2 text-[rgba(143,12,63,1)]">
                            <span className="text-primary-blue sm:tex-xl lg:text-2xl font-semibold">
                              {minutes}
                            </span>{" "}
                            Minutes
                          </div>
                          <span className="text-[rgba(143,12,63,1)] sm:tex-xl lg:text-2xl font-semibold mt-4">
                            :
                          </span>
                          <div className="border-primary-blue text-sm md:text-base bg-light-blue flex flex-col items-center rounded-lg w-[60px] sm:w-[70px] border p-2 text-[rgba(143,12,63,1)]">
                            <span className="text-primary-blue sm:tex-xl lg:text-2xl font-semibold">
                              {seconds}
                            </span>{" "}
                            Seconds
                          </div>
                        </div>
                      )}
                    />
                    <h1 className="text-primary-blue font-semibold">
                      Giveaway Live 🎉
                    </h1>
                    <div className="flex gap-4 items-center ">
                   

                      <button
                        disabled={isSubscribed}
                        onClick={CheckIsLogin}
                        className="bg-dark-blue py-3 px-4 rounded-lg w-fit text-white transition-all duration-300 ease-in-out hover:bg-primary-blue"
                      >
                        {isSubscribed ? "You're In" : "Enter Now"}
                      </button>
                      {isSubscribed ? (
                        <button   onClick={() => redirectToResult(data)} className="bg-dark-blue py-3 px-4 rounded-lg w-fit text-white transition-all duration-300 ease-in-out hover:bg-primary-blue">
                          View Details
                        </button>
                      ) : (
                        ""
                      )}
                    </div>
                  </>
                )}

                {isEnded && (
                  <div className="flex gap-4 justify-center items-center">
                    <button
                      disabled
                      className="bg-gray-400 py-3 px-4 rounded-lg w-fit text-white"
                    >
                      Ended
                    </button>
                    <button
                      onClick={() => redirectToResult(data)}
                      className="bg-gray-400 py-3 px-4 rounded-lg w-fit text-white"
                    >
                      View Result
                    </button>
                  </div>
                )}
              </div>
            </div>
          );
        })}

        <div className="pt-8 pb-14" ref={subscriptionSectionRef}>
          <h2 className="text-[#0E2F43] text-center text-cxl mb-4">
            Subscription
          </h2>
          <div className="mt-14 grid grid-cols-1 sm:grid-cols-2 gap-10 lg:gap-14 w-full mx-auto md:w-[700px] lg:w-[800px]">
            {Subscription?.planDetails?.slice(3, 5).map((el, index) => (
              <SubscriptionPlanCard
                key={index} // Always include a key when mapping elements
                planData={el}
              />
            ))}
          </div>
        </div>
      </div>
      {isLoginOpen && (
        <LoginModal
          closeLoginModal={closeLoginModal}
          switchToSignup={switchToSignup}
          switchToForgotPass={switchToForgotPass}
        />
      )}

      {isSignupOpen && (
        <SignupModal
          closeSignupModal={closeSignupModal}
          switchToLogin={switchToLogin}
        />
      )}

      {isForgotPasswordOpen && (
        <ForgotPassword
          closeForgotPassModal={closeForgotPassModal}
          switchToForgotPass={switchToForgotPass}
        />
      )}

      <CongratsModal
        data={verifiyplandata && verifiyplandata}
        setShowCongratsModal={setShowCongratsModal}
        showCongratsModal={showCongratsModal}
      />
    </div>
  );
};

export default Giveaways;

const DevelopmentUrl =  "https://devapi.saveon.co.nz";
const ProductionUrl = "https://api.saveon.co.nz";
  
let ProjectMode = "dev";

export const BaseUrl = ProjectMode === "dev" ? DevelopmentUrl : ProductionUrl;
;
 
export let getUserdata=JSON.parse(localStorage.getItem("SaveOn_Web_Cread"))

